<template>
  <Modal
    v-model="visible"
    title="新增人员信息"
    :mask-closable="false"
    @on-cancel="returnPath">
    <Form ref="formPersonAdd" :model="userItem" :rules="ruleValidate" :label-width="120">
      <Form-item label="姓名" prop="LegalName">
        <Input v-model="userItem.LegalName" placeholder="请输入姓名"></Input>
      </Form-item>
      <Form-item label="备注" prop="Tag">
        <Input v-model="userItem.Tag" placeholder="人员备注信息，如：“项目经理”、“信息专员”、“人事专员”等"></Input>
      </Form-item>
      <Form-item label="身份证号" prop="IdNumber">
        <Input v-model="userItem.IdNumber" placeholder="请输入身份证号"></Input>
      </Form-item>
      <Form-item label="手机号码" prop="Tel">
        <Input v-model="userItem.Tel" placeholder="请输入手机号码"></Input>
      </Form-item>
      <FormItem label="用户权限">
        <Checkbox :value="true" :disabled="true">项目查看</Checkbox>
        <Checkbox v-model="permissions.canEdit">项目修改</Checkbox>
        <Checkbox v-model="permissions.canAssign">关联人员</Checkbox>
        <Checkbox v-model="permissions.canUploadReport">上报月报</Checkbox>
        <Checkbox v-model="permissions.canViewReport">查看月报</Checkbox>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" :loading="submitting" @click="addPerson">提交</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ['data', 'show'],
  watch: {
    'show': function (newVal) {
      this.visible = newVal
    }
  },
  data () {
    return {
      permissions: {
      },
      userItem: {
        LegalName: '',
        IdNumber: '',
        Tel: '',
        Permission: 1,    // 基础权限
        ProjectId: this.data,
        Tag: ''
      },
      ruleValidate: {
        LegalName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        Tag: [
          { type: 'string', pattern: '^((?!施工人员).)*$', message: '备注不能含有"施工人员"', trigger: 'blur' }
        ],
        IdNumber: [
          { required: true, message: '身份证号码不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号码不合法', trigger: 'blur' }
        ],
        Tel: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号码不合法', trigger: 'blur' }
        ]
      },
      submitEnabled: true,
      submitting: false,
      visible: false
    }
  },
  mounted () {
    this.permissions = {
      canEdit: this.getPermission(2),
      canAssign: this.getPermission(8),
      canUploadReport: this.getPermission(4),
      canViewReport: this.getPermission(32)
    }
  },
  methods: {
    getPermission (p) {
      return this.userItem.Permission & p
    },
    procPermissions () {
      this.userItem.Permission = this.permissions.canEdit * 0x2 + this.permissions.canAssign * 0x8 +
        this.permissions.canUploadReport * 0x4 + this.permissions.canViewReport * 0x20
    },
    addPerson () {
      this.procPermissions()
      this.$refs['formPersonAdd'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.modal_loading = true
          this.$Api.Project.addProjectUser(this.userItem).then(() => {
            this.$Spin.hide()
            this.$Message.success('操作成功！')
            this.permissions = {}
            this.userItem = {
              Name: '',
              IdNumber: '',
              MobilePhoneNumber: '',
              Permission: 1,
              ProjectId: this.data,
              Tag: ''
            }
            this.returnPath()
          })
          .catch(err => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$Notice.error({
              title: '操作失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('postAdd', null)
      this.$Modal.remove()
    }
  }
}
</script>

<style>
</style>
